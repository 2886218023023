





















































import { Vue, Component, Mixins, Ref } from 'vue-property-decorator';

import DataTable from "@monade/vue-components/src/components/DataTable.vue";
import CategoryGroupsMixin, { CategoryGroupsMixinTags } from '@/mixins/http/CategoryGroupsMixin';
import { CategoryGroup } from '@/models';
import { easync } from '@/utils/http';
import { showErrorToast, showToast } from '@/utils/Toast';
import { RegisterHttp } from '@/utils/Decorators';
import { RequestData } from '@/store/types/RequestStore';
import ConfirmModal from '@/components/ConfirmModal.vue';
import CategoryGroupCreateModal from '@/components/CategoryGroup/CreateModal.vue';

@Component({
  components: {
    DataTable,
    ConfirmModal,
    CategoryGroupCreateModal
  }
})
export default class StoreFrontContentCategoryGroupList extends Mixins(CategoryGroupsMixin) {
  private categoryGroups: CategoryGroup[] = [];

  @RegisterHttp(CategoryGroupsMixinTags.Index) readonly indexRequest!: RequestData;

  @Ref() readonly confirmModal!: ConfirmModal;
  @Ref() readonly createModal!: CategoryGroupCreateModal;

  created() {
    this.loadCategoryGroups();
  }

  get storeFrontId() {
    return this.$route.params.id;
  }

  get columns() {
    return [
      { name: "name", label: "Nome" },
      { name: "actions", label: "" }
    ];
  }

  get payload() {
    return {};
  }

  get loading() {
    return this.indexRequest?.loading;
  }

  private async loadCategoryGroups() {
    const [data, errors] = await easync(
      this.getCategoryGroups(this.payload, CategoryGroupsMixinTags.Index)
    );

    if (data) {
      this.categoryGroups = data;
    } else {
      this.categoryGroups = [];
      showErrorToast('Si è verificato un problema');
    }
  }

  private onSelected(value: CategoryGroup) {
    this.goToCategoryGroupShow(value.id);
  }

  private goToCategoryGroupShow(id: string) {
    this.$router.push({
      name: 'category_groups_show',
      params: { id: this.storeFrontId, categoryGroupId: id }
    });
  }

  private async openConfirmModal(value: CategoryGroup) {
    const message = "Sei sicuro di voler eliminare questo BannerGroup?";
    const confirmed = await (this.confirmModal as any).confirm(message);
    if (confirmed) {
      this.onDelete(value.id);
    }
  }

  private async onDelete(id: string) {
    const [data, errors] = await easync(this.destoryCategoryGroup(id, CategoryGroupsMixinTags.Destroy + id));

    if (errors) {
      showErrorToast('Si è verificato un errore durante la cancellazione');
      return;
    }

    showToast('CategoryGroup eliminato con successso!');
    this.loadCategoryGroups();
  }

  private onCreateCategoryGroup() {
    this.createModal.show();
  }
}
